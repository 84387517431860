.HatPatternLabel {
    text-align: left;
}

.title {
    text-align: center;
}

.toolbarBnt {
    margin-left: 90%;
    min-width: 75px;
    background-color: white;
    color: black;
}

.toolbarBnt-overlay {
    border-color: lightgrey;
    border-width: 5px;
    background-color: white;
}

.submitBtn {
    margin-left: 35%;
}
